import React from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {nextStepAction, prevStepAction, finalStepAction, firstStepAction} from "../redux/actions/actions";
import {WelcomeSlide} from '../components/slides/WelcomeSlide';
import {GetReadySlide} from '../components/slides/GetReadySlide';
import {UsualSizeSlide} from '../components/slides/UsualSizeSlide';
import {QuestionSlide} from '../components/slides/QuestionSlide';
import {FinalSlide} from '../components/slides/FinalSlide';
import {MeasureSlide} from '../components/slides/MeasureSlide';
import {FootprintSlide} from '../components/slides/FootprintSlide';

export const StepsController = () => {
    const dispatch = useDispatch();

    const nextStep = () => {
        dispatch(nextStepAction());
    };

    const prevStep = () => {
        dispatch(prevStepAction());
    };

    const finalStep = () => {
        dispatch(finalStepAction());
    };

    const firstStep = () => {
        dispatch(firstStepAction());
    };

    const step = useSelector(state => state.currentStep);
    let Slide;
    const slideProps = {nextStep, prevStep, stepNumber: step};
    switch (step) {
        case 0: {
            Slide = WelcomeSlide;
            break;
        }
        case 1: {
            Slide = GetReadySlide;
            break;
        }
        case 2: {
            Slide = UsualSizeSlide;
            break;
        }
        case 3: {
            Slide = MeasureSlide;
            slideProps.url = "https://vk.com/video_ext.php?oid=-89156825&id=456239677&hd=2&hash=0a01920e3d7a0c45&autoplay=1;";
            slideProps.title = "Мерка 1 - Длина";
            slideProps.text = "На лист бумаги поставьте стопу и перенесите на нее вес тела. Поставьте коробок спичек вплотную к самому длинному пальцу. Не отрывая пятку от пола поднимите пальцы вверх, отметьте ручкой место соприкосновения пальцев с коробком. То же самое повторите с пяткой. Измерьте расстояние между точками.";
            break;
        }
        case 4: {
            Slide = MeasureSlide;
            slideProps.url = "https://vk.com/video_ext.php?oid=-89156825&id=456239678&hd=2&hash=aadd14b082959eeb&autoplay=1;";
            slideProps.title = "Мерка 2 - Обхват пучка";
            slideProps.text = "Положите гибкий метр под самое широкое место стопы, перед пальцами. Чаще всего оно находится около косточки большого пальца. Перенесите вес тела на стопу. Измерьте длину окружности стопы.";
            break;
        }
        case 5: {
            Slide = MeasureSlide;
            slideProps.url = "https://vk.com/video_ext.php?oid=-89156825&id=456239679&hd=2&hash=de60d7e102296a31&autoplay=1;";
            slideProps.title = "Мерка 3 - Подъем";
            slideProps.text = "Переместите метр под сгиб стопы. Чуть ниже точки сгиба стопы, измерьте длину окружности подъема.";
            break;
        }
        case 6: {
            Slide = QuestionSlide;
            slideProps.finalStep = finalStep;
            slideProps.questionText = "Обувь будет выше щиколотки?";
            break;
        }
        case 7: {
            Slide = MeasureSlide;
            slideProps.url = "https://vk.com/video_ext.php?oid=-89156825&id=456239693&hd=2&hash=aaeb3f80bff8836b&autoplay=1;";
            slideProps.title = "Мерка 4 - Обхват голени";
            slideProps.text = "Отмерьте 17 см от пола на ноге. В этом месте измерьте длину окружности голени.";
            break;
        }
        case 8: {
            Slide = MeasureSlide;
            slideProps.url = "https://vk.com/video_ext.php?oid=-89156825&id=456239696&hd=2&hash=9b0a9ef1c0b45fa2&autoplay=1;";
            slideProps.title = "Мерка 5 - Обхват икры";
            slideProps.text = "Если вы планируете носить штаны внутрь обуви, измеряйте икру вместе с штаниной. Измерьте длину окружности икры в самом широком месте. После, измерьте расстояние от пола до самого широкого места икры.";
            slideProps.isFourInputs = true;
            break;
        }
        case 9: {
            Slide = FootprintSlide;
            break;
        }
        case 10: {
            Slide = MeasureSlide;
            slideProps.url = "https://vk.com/video_ext.php?oid=-89156825&id=456239695&hd=2&hash=a057778093a8ecba&autoplay=1;";
            slideProps.title = "Мерка 1.1 - Длина обвода";
            slideProps.text = "Поставьте стопу на лист бумаги, перенесите на нее вес. Возьмите ручку или карандаш держа ее прямо, без наклонов, обведите стопу по контуру, уберите стопу. Отметьте крайние точки пальцев и пятки и измерьте расстояние между ними.";
            break;
        }
        case 11: {
            Slide = MeasureSlide;
            slideProps.url = "https://vk.com/video_ext.php?oid=-89156825&id=456239694&hd=2&hash=0f3b60990a378be9&autoplay=1;";
            slideProps.title = "Мерка 2.1 - Ширина пучка";
            slideProps.text = "Отметьте на обведенном контуре самое широкое место и измерьте его. Сделайте фото обвода стопы с отмеченными местами точек длины и пучка. Оно вам понадобится в чате с менеджером.";
            break;
        }
        case 12: {
            Slide = FinalSlide;
            slideProps.firstStep = firstStep;
            break;
        }
        default: {
            Slide = () => <div>Ошибка, пожалуйста свяжитесь с администратором!</div>;
        }
    }

    return (
        <div className="slide-container">
            <Slide {...slideProps} />
        </div>
    );
}




