import React from 'react';
import { Button } from './../Button';

export const WelcomeSlide = ({nextStep}) => {
    return (
        <React.Fragment>
            <div className="firstSlide">
                <div>
                    <div className="fadeIn">
                        <h1>Сниматель мерок</h1>
                        <p>Чтобы снять мерки Вам потребуется 15 минут. Столько же мы тратим на сборы в торговый центр.</p>
                    </div>
                    <div className="button-container">
                        <Button onClick={nextStep} isNext={true}>Начать</Button>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

