import React from 'react';
import {Button} from "../Button";

export const FootprintSlide = ({nextStep}) => {
    return (
        <React.Fragment>
            <div className="firstSlide">
                <div>
                    <div className="fadeIn">
                        <h1>Переходим к работе со следом</h1>
                        <p> </p>
                    </div>
                    <div className="button-container">
                        <Button onClick={nextStep} isNext={true}>Приступить</Button>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

