import React from 'react';
import {useSelector} from "react-redux";
import {ButtonContainer} from "../ButtonContainer/ButtonContainer";
import {SimpleButton, Button} from "../Button";

export const FinalSlide = ({prevStep, firstStep}) => {
    const stepsData = useSelector(state => state.stepsData);
    const formResultData = () => {
        const size = stepsData.filter(stepData => stepData.stepNumber === 2)[0].inputValue;

        let leftSizes = `${1} - ${stepsData[2].inputNumber1} (${stepsData[8].inputNumber1}) / ${2} - ${stepsData[3].inputNumber1} (${stepsData[9].inputNumber1}) / `;
        let rightSizes = `${1} - ${stepsData[2].inputNumber2} (${stepsData[8].inputNumber2}) / ${2} - ${stepsData[3].inputNumber2} (${stepsData[9].inputNumber2}) / `;

        leftSizes += `${3} - ${stepsData[4].inputNumber1}`;
        rightSizes += `${3} - ${stepsData[4].inputNumber2}`;

        // for (let i = 4; i <= 5; i++) {
        //     leftSizes += `${i - 1} - ${stepsData[i].inputNumber1} \`;
        //     rightSizes += `${i - 1} - ${stepsData[i].inputNumber2} \`;
        // }

        if (stepsData[5].answer) {
            leftSizes += ` / ${4} - ${stepsData[6].inputNumber1} (17) / ${5} - ${stepsData[7].inputNumber1} (${stepsData[7].inputNumber2})`;
            rightSizes += ` / ${4} - ${stepsData[6].inputNumber2} (17) / ${5} - ${stepsData[7].inputNumber3} (${stepsData[7].inputNumber4})`;
        }

        return `Размер: ${size}\nЛевая: ${leftSizes}\nПравая: ${rightSizes} `;
    };

    return (
        <React.Fragment>
            <div className="fadeIn">
                <h1>Мерки сняты, спасибо</h1>
                <p className="finalText">Нажмите «Скопировать» и вставьте в чат с Вашим менеджером. Приложите фото следа, а также фото ног сверху и сбоку</p>
                <textarea id="textArea" className="resultTextArea">
                    {formResultData()}
                </textarea>
                <div className="copyButton">
                <Button isNext={true} onClick={() => {
                    const textArea = document.getElementById('textArea');
                    textArea.select();
                    document.execCommand('copy');
                    const copyButton = document.querySelector('.copyButton .next-button');
                    copyButton.innerText = "Текст скопирован!";
                }}>Скопировать</Button>
                </div>
                <div className="fromStart">
                    <SimpleButton onClick={firstStep}>Снять мерки заново</SimpleButton>
                </div>
            </div>
            <ButtonContainer showNextButton={false} prevStep={prevStep}/>
        </React.Fragment>
    );
};
