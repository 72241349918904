import React from 'react';
import {useSelector, useDispatch} from "react-redux";
import {updateStepData} from "../../redux/actions/actions";
import {ButtonContainer} from "../ButtonContainer/ButtonContainer";

export const MeasureSlide = ({prevStep, nextStep, title, text, url, stepNumber, isFourInputs}) => {
    const stepData = useSelector(state => state.stepsData.filter(stepData => stepData.stepNumber === stepNumber)[0]);
    const dispatch = useDispatch();

    const onChange = (e) => {
        const newStepData = {...stepData};
        newStepData[e.target.id] = e.target.value;
        dispatch(updateStepData(stepNumber, newStepData));
    };

    const showNextButton = () => {
        if (stepData.inputNumber1 === "" || stepData.inputNumber2 === "") {
            return false;
        } else if (isFourInputs) {
            if (stepData.inputNumber3 === ""|| stepData.inputNumber4 === "" ) {
                return false;
            }
        }
        return true;
    };
    return (
        <React.Fragment>
            <div class="fadeIn">
                <h1 className="measureTitle">{title}</h1>
                <div className="measureWrapper">
                    <iframe title="title" allowfullscreen="allowfullscreen" className='video' src={url}/>
                    <div className="measureInfo">
                        <p className="measureText">{text}</p>
                        { (!isFourInputs) ? (
                        <React.Fragment>
                            <div className="measureInputs">
                                <div className="measureLeft">
                                    <p className="inputName">Левая:</p>
                                    <div className="measureInput">
                                        <input  id="inputNumber1" value={stepData.inputNumber1} onChange={onChange}/> <span>см</span>
                                    </div>
                                </div>
                                <div className="measureRight">
                                    <p className="inputName">Правая:</p>
                                    <div className="measureInput">
                                        <input id="inputNumber2" value={stepData.inputNumber2} onChange={onChange}/> <span>см</span>
                                    </div>        
                                </div>
                            </div>
                        </React.Fragment>)
                    :(
                        <React.Fragment>
                            <div className="measureFourInputs">
                                <div className="measureRow">
                                    <div className="measureLeft">
                                        <p className="inputName">Левая окруж:</p>
                                        <div className="measureInput">
                                            <input  id="inputNumber1" value={stepData.inputNumber1} onChange={onChange}/> <span>см</span>
                                        </div>
                                    </div>
                                    <div>
                                        <p className="inputName">Правая окруж:</p>
                                        <div className="measureInput">
                                            <input id="inputNumber3" value={stepData.inputNumber3} onChange={onChange}/> <span>см</span>
                                        </div> 
                                    </div>
                                </div>
                                <div className="measureRow">    
                                    <div className="measureLeft">
                                        <p className="inputName">Левая от пола:</p>
                                        <div className="measureInput">
                                            <input  id="inputNumber2" value={stepData.inputNumber2} onChange={onChange}/> <span>см</span>
                                        </div>        
                                    </div>
                                    <div className="measureRight">
                                        <p className="inputName">Правая от пола:</p>
                                        <div className="measureInput">
                                            <input id="inputNumber4" value={stepData.inputNumber4} onChange={onChange}/> <span>см</span>
                                        </div>        
                                    </div>
                                </div>    
                            </div>    
                        </React.Fragment>)}
                    </div>
                </div>
            </div>
            <ButtonContainer prevStep={prevStep} nextStep={nextStep} disableNextButton={false} showNextButton={showNextButton()}/>
    </React.Fragment>
    )
};
        